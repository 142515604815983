import { navigate } from 'gatsby-link';

import { companyKnownAction, companyVerificationKnownAction } from '../reducers/companyReducer';
import { loadingEndAction, loadingStartAction } from '../reducers/loadingReducer';
import { hideNotificationAction, showNotificationAction } from '../reducers/notificationReducer';
import { companyRequestKnownAction } from '../reducers/projectRequestReducer';
import * as constant from '../utils/constant';
import { COMPANY_VERIFICATION_GET_SUCCESS_ERROR_CODE } from '../utils/constant';
import debug from '../utils/debug';
import { mutate, query } from '../utils/graphql';
import {
  ADD_COMPANY,
  ADD_COMPANY_PROJECT_REQUEST,
  ADD_COMPANY_VERIFICATION,
  ADMIN_APPROVE_COMPANY_VERIFICATION,
  ADMIN_DENY_COMPANY_VERIFICATION,
  ADMIN_GET_ALL_COMPANY_VERIFICATION,
  GET_COMPANY_VERIFICATION,
  UPDATE_COMPANY,
  UPDATE_COMPANY_PROJECT_REQUEST,
  UPDATE_COMPANY_VERIFICATION,
} from '../utils/graphqlStrings';

export const COMPANY_KNOWN = 'COMPANY_KNOWN';
export const COMPANY_VERIFICATION_KNOWN = 'COMPANY_VERIFICATION_KNOWN';

export const addCompany = (): Function => async (dispatch: Function): Promise<boolean> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  dispatch(companyKnownAction({ loaded: false }));
  const { data, error } = await mutate(ADD_COMPANY, { fetchPolicy: 'no-cache' });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.COMPANY_ADD_FAIL }));
    return false;
  }

  if (data?.addCompany) {
    dispatch(companyKnownAction(data.addCompany));
    // navigate('/company/verification');
    return true;
  }
  return false;
};

export const updateCompany = (company: Company): Function => async (
  dispatch: Function
): Promise<void> => {
  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  dispatch(companyKnownAction({ loaded: false }));
  const { data, error } = await mutate(UPDATE_COMPANY, company, { fetchPolicy: 'no-cache' });
  dispatch(loadingEndAction());

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: constant.COMPANY_ADD_FAIL }));
    return;
  }
  if (data?.updateCompany) {
    dispatch(companyKnownAction(data.updateCompany));
    navigate('/company/verification');
    return;
  }
};

// 获取公司验证
export const getCompanyGetVerification: Function = (companyId: string) => async (
  dispatch: Function
): Promise<void> => {
  debug('Get verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = {
    companyId,
  };
  const { data, error } = await query(GET_COMPANY_VERIFICATION, variables);
  if (error) {
    if (error.code !== COMPANY_VERIFICATION_GET_SUCCESS_ERROR_CODE) {
      dispatch(
        showNotificationAction({
          severity: 'error',
          message: constant.COMPANY_VERIFICATION_GET_FAIL,
        })
      );
    }

    dispatch(loadingEndAction());
  }
  if (data && data?.companyGetVerification) {
    const verification = data.companyGetVerification;
    debug('Get company verification successfully');
    debug(verification);
    dispatch(companyVerificationKnownAction(verification));
    dispatch(hideNotificationAction());
    dispatch(loadingEndAction());
  }
};

export const addVerification: Function = (verification: CompanyVerification) => async (
  dispatch: Function
): Promise<void> => {
  debug('Add companyAddVerification verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, loading, error } = await query(ADD_COMPANY_VERIFICATION, { verification });
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    dispatch(
      showNotificationAction({ severity: 'error', message: constant.COMPANY_VERIFICATION_ADD_FAIL })
    );
  }
  if (data && data?.companyAddVerification) {
    const companyAddVerification = data.companyAddVerification;
    debug('Add companyAddVerification verification successfully');
    debug(companyAddVerification);
    dispatch(companyVerificationKnownAction(companyAddVerification));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '提交成功',
      })
    );
  }
};

export const updateVerification: Function = (verification: CompanyVerification) => async (
  dispatch: Function
): Promise<void> => {
  debug('Update companyUpdateVerification verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const { data, loading, error } = await query(UPDATE_COMPANY_VERIFICATION, { verification });
  if (!loading) {
    dispatch(loadingEndAction());
  }
  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: constant.COMPANY_VERIFICATION_UPDATE_FAIL,
      })
    );
    dispatch(loadingEndAction());
  }
  if (data && data?.companyUpdateVerification) {
    const updatedVerification = data.companyUpdateVerification;
    debug('Update companyUpdateVerification verification successfully');
    debug(updatedVerification);
    dispatch(companyVerificationKnownAction(updatedVerification));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '编辑成功',
      })
    );
  }
};

export const addCompanyRequest: Function = (request: ProjectRequestInfo) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('Add company verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { request };

  const { data, loading, error } = await mutate(ADD_COMPANY_PROJECT_REQUEST, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '添加项目失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.companyAddProjectRequest) {
    const companyAddProjectRequest = data.companyAddProjectRequest;
    debug('Add companyAddProjectRequest successfully');
    debug(companyAddProjectRequest);
    dispatch(companyRequestKnownAction(companyAddProjectRequest));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '添加项目成功',
      })
    );
    return companyAddProjectRequest as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

export const updateCompanyRequest: Function = (request: ProjectRequestInfo) => async (
  dispatch: Function
): Promise<ProjectRequestInfo> => {
  debug('update company verification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());
  const variables = { request };

  const { data, loading, error } = await mutate(UPDATE_COMPANY_PROJECT_REQUEST, variables);
  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(showNotificationAction({ severity: 'error', message: '编辑项目失败' }));
    return {} as ProjectRequestInfo;
  }

  if (data && data?.companyUpdateProjectRequest) {
    const companyUpdateProjectRequest = data.companyUpdateProjectRequest;
    debug('update companyUpdateProjectRequest successfully');
    debug(companyUpdateProjectRequest);
    dispatch(companyRequestKnownAction(companyUpdateProjectRequest));
    dispatch(
      showNotificationAction({
        severity: 'success',
        message: '编辑项目成功',
      })
    );
    return companyUpdateProjectRequest as ProjectRequestInfo;
  }
  return {} as ProjectRequestInfo;
};

// 获取公司列表
export const adminGetAllCompanyVerification: Function = () => async (
  dispatch: Function
): Promise<CompanyVerification[]> => {
  debug('Get adminGetAllCompanyVerification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await query(ADMIN_GET_ALL_COMPANY_VERIFICATION);

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: '获取企业信息失败',
      })
    );

    dispatch(loadingEndAction());
    return [] as CompanyVerification[];
  }

  if (data && data?.adminGetAllCompanyVerification) {
    const verification = data.adminGetAllCompanyVerification;
    debug('admin Get company all verification successfully');
    debug(verification);
    dispatch(hideNotificationAction());
    dispatch(loadingEndAction());
    return data.adminGetAllCompanyVerification as CompanyVerification[];
  }
  return [] as CompanyVerification[];
};

export const adminApproveCompanyVerification: Function = (verificationId: string) => async (
  dispatch: Function
): Promise<boolean> => {
  debug('update adminApproveCompanyVerification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await mutate(ADMIN_APPROVE_COMPANY_VERIFICATION, {
    verificationId,
  });

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: '操作失败',
      })
    );

    dispatch(loadingEndAction());
    return false;
  }
  if (data && data?.adminApproveCompanyVerification) {
    const verification = data.adminApproveCompanyVerification;
    debug(' adminApproveCompanyVerification successfully');
    debug(verification);
    dispatch(hideNotificationAction());
    dispatch(loadingEndAction());
    return true;
  }
  return false;
};

export const adminDenyCompanyVerification: Function = (
  verificationId: string,
  comment: string
) => async (dispatch: Function): Promise<boolean> => {
  debug('update adminDenyCompanyVerification');

  dispatch(loadingStartAction());
  dispatch(hideNotificationAction());

  const { data, loading, error } = await mutate(ADMIN_DENY_COMPANY_VERIFICATION, {
    verificationId,
    comment,
  });

  if (!loading) {
    dispatch(loadingEndAction());
  }

  if (error) {
    dispatch(
      showNotificationAction({
        severity: 'error',
        message: '操作失败',
      })
    );

    dispatch(loadingEndAction());
    return false;
  }
  if (data && data?.adminDenyCompanyVerification) {
    const verification = data.adminDenyCompanyVerification;
    debug(' adminDenyCompanyVerification successfully');
    debug(verification);
    dispatch(hideNotificationAction());
    dispatch(loadingEndAction());
    return true;
  }
  return false;
};
